import request from "@/util/request";
const urlPrefix = "v1/admin/followRecords";

export default {
  list(params) {
    return request.get(`${urlPrefix}`, params);
  },
  create(params) {
    return request.post2(`${urlPrefix}`, params);
  },
  update(params) {
    return request.put(`${urlPrefix}`, params);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
  delete(ids) {
    return request.delete(`${urlPrefix}/${ids}`);
  },
};
