import axios from "axios";
import qs from "qs";
import { notification, message } from "antd";
import { ResultCodeEnum } from "@/constants";
import urls from "@/api/urls";
import config from "@/config";

// 控制台打印模拟数据
import { logMockAxios } from "../../mock/core/logMock";
// 引入mock数据
if (__MOCK__) {
  require("../../mock/core");
}

const headers = {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  Client: "web",
};
const headers2 = {
  "Content-Type": "application/json;charset=UTF-8",
  Client: "web",
};

const tokenText = "token";
const tokenType = "Bearer";

const instance = axios.create({
  baseURL: config.apiPrefix,
  withCredentials: true,
  headers: sessionStorage.getItem("token")
    ? {
        ...headers,
        ...{
          Authorization: `${tokenType} ${sessionStorage.getItem(tokenText)}`,
        },
      }
    : headers,
  transformRequest: [
    function(data) {
      // 对 data 进行任意转换处理
      let ret = "";
      if (data) {
        ret = qs.stringify(data);
        return ret;
      }
    },
  ],
});
const instance2 = axios.create({
  baseURL: config.apiPrefix,
  withCredentials: true,
  headers: sessionStorage.getItem("token")
    ? {
        ...headers2,
        ...{
          Authorization: `${tokenType} ${sessionStorage.getItem(tokenText)}`,
        },
      }
    : headers2,
  transformRequest: [
    function(data) {
      // 对 data 进行任意转换处理
      let ret = "";
      if (data) {
        ret = JSON.stringify(data);
        return ret;
      }
    },
  ],
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance2.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    // 控制台打印模拟数据
    logMockAxios(response);

    const data = response.data;
    if (typeof data !== "object") {
      throw new Error("响应数据必须为object类型(typeof)");
    }

    if (data.code === ResultCodeEnum.unauthenticated.code) {
      // 未登录, 记录当前访问路径
      if (location.href.indexOf("login.html") > 0) {
        return response.data;
      }
      sessionStorage.setItem("gotoBeforeUrl", location.href);
      location.href = "./login.html";
      return;
    } else if (data.code === ResultCodeEnum.accountDisabled.code) {
      // 账号被禁用
    } else if (data.code === ResultCodeEnum.forbidden.code) {
      // 权限不足
    } else if (data.code === ResultCodeEnum.inputError.code) {
      // 参数错误
      message.warning(data.errorMsg ? data.errorMsg : "参数错误");
    } else if (data.code === ResultCodeEnum.fail.code) {
      // 其他错误
      message.error(data.errorMsg ? data.errorMsg : "操作失败");
    } else if (data.code === ResultCodeEnum.error.code) {
      // 系统繁忙, 请稍后重试
      message.error(data.errorMsg ? data.errorMsg : "操作失败");
    } else if (data.code === ResultCodeEnum.kickout.code) {
      // 您的账号在另一地点登录, 您被迫下线
      message.error(data.errorMsg ? data.errorMsg : "您的账号在另一地点登录, 您被迫下线", () => {
        location.href = "./login.html";
      });
    }
    return data;
  },
  (error) => {
    notification["error"]({
      message: "网络请求失败，请重试",
      description: "",
      key: "response_error",
      duration: 2,
    });
    return Promise.reject(error);
  }
);
instance2.interceptors.response.use(
  (response) => {
    // 控制台打印模拟数据
    logMockAxios(response);

    const data = response.data;
    if (typeof data !== "object") {
      throw new Error("响应数据必须为object类型(typeof)");
    }

    if (data.code === ResultCodeEnum.unauthenticated.code) {
      // 未登录, 记录当前访问路径
      if (location.href.indexOf("login.html") > 0) {
        return response.data;
      }
      sessionStorage.setItem("gotoBeforeUrl", location.href);
      location.href = "./login.html";
      return;
    } else if (data.code === ResultCodeEnum.accountDisabled.code) {
      // 账号被禁用
    } else if (data.code === ResultCodeEnum.forbidden.code) {
      // 权限不足
    } else if (data.code === ResultCodeEnum.inputError.code) {
      // 参数错误
      message.warning(data.errorMsg ? data.errorMsg : "参数错误");
    } else if (data.code === ResultCodeEnum.fail.code) {
      // 其他错误
      message.error(data.errorMsg ? data.errorMsg : "操作失败");
    } else if (data.code === ResultCodeEnum.error.code) {
      // 系统繁忙, 请稍后重试
      message.error(data.errorMsg ? data.errorMsg : "操作失败");
    } else if (data.code === ResultCodeEnum.kickout.code) {
      // 您的账号在另一地点登录, 您被迫下线
      message.error(data.errorMsg ? data.errorMsg : "您的账号在另一地点登录, 您被迫下线", () => {
        location.href = "./login.html";
      });
    }
    return data;
  },
  (error) => {
    notification["error"]({
      message: "网络请求失败，请重试",
      description: "",
      key: "response_error",
      duration: 2,
    });
    return Promise.reject(error);
  }
);

export default {
  get: (url, data) => {
    return instance.get(url, data ? { params: data } : {});
  },
  get2: (url, data) => {
    return instance2.get(url, data ? { params: data } : {});
  },
  post: (url, data) => {
    return instance.post(url, data);
  },
  post2: (url, data) => {
    return instance2.post(url, data);
  },
  put: (url, data) => {
    return instance.put(url, data);
  },
  put2: (url, data) => {
    return instance2.put(url, data);
  },
  delete: (url, data) => {
    return instance.delete(url, data ? { params: data } : {});
  },
  delete2: (url, data) => {
    return instance2.delete(url, data ? { params: data } : {});
  },
  patch: (url, data) => {
    return instance.patch(url, data);
  },
  patch2: (url, data) => {
    return instance2.patch(url, data);
  },
  upload: function(param, config = {}) {
    return axios({
      method: "post",
      url: urls.uploadImage,
      data: param,
      ...config,
    });
  },
};
