import request from "@/util/request";
const urlPrefix = "v1/admin/mycenter";

export default {
  getLoginUser() {
    return request.get(`${urlPrefix}/getLoginUser`);
  },
  updatePwd(params) {
    return request.post(`${urlPrefix}/updatePwd`, params);
  },
};
