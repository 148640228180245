import request from "@/util/request";

const urlPrefix = `v1/admin/upmsUsers`;

export default {
  list(params) {
    return request.get(`${urlPrefix}`, {
      ...params,
    });
  },
  create(params) {
    return request.post(`${urlPrefix}`, {
      ...params,
    });
  },
  update(id, params) {
    return request.put(`${urlPrefix}/${id}`, {
      ...params,
    });
  },
  delete(id) {
    return request.delete(`${urlPrefix}/${id}`);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
  disable(id) {
    return request.put(`${urlPrefix}/disable/${id}`);
  },
  enable(id) {
    return request.put(`${urlPrefix}/enable/${id}`);
  },
  addScreeningCondition(params) {
    return request.put2(`${urlPrefix}/addScreeningCondition`, params);
  },
  resetPwd(userId) {
    return request.post(`${urlPrefix}/resetPwd?userId=${userId}`, {});
  },
};
