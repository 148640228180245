import { ResultCodeEnum } from "@/constants";
// url
import urls from "@/api/urls";
// Api
import baseApi from "@/api/baseApi";
import loginApi from "@/api/loginApi";
import carouselApi from "@/api/carouselApi";
import memberApi from "@/api/memberApi";
import userApi from "@/api/userApi";
import roleApi from "@/api/roleApi";
import permissionsApi from "@/api/permissionsApi";
import districtApi from "./districtApi";
import mdConfiguresApi from "./mdConfiguresApi";
import dictApi from "./dictApi";

import enterprisesApi from "@/api/enterprisesApi";
import enterpriseFinancesApi from "@/api/enterpriseFinancesApi";
import operationLogsApi from "@/api/operationLogsApi";
import housesApi from "@/api/housesApi";
import banksApi from "@/api/banksApi";
import enterpriseLoansApi from "@/api/enterpriseLoansApi";
import mycenterApi from "@/api/mycenterApi";
import commonFilesApi from "@/api/commonFilesApi";
import upmsOrgsApi from "@/api/upmsOrgsApi";
import channelApi from "@/api/channelApi";
import followRecordsApi from "@/api/followRecordsApi";

import bankUsersApi from "@/api/bankUsersApi";

export {
  ResultCodeEnum,
  urls,
  baseApi,
  loginApi,
  carouselApi,
  memberApi,
  userApi,
  roleApi,
  permissionsApi,
  districtApi,
  mdConfiguresApi,
  dictApi,
  enterprisesApi,
  enterpriseFinancesApi,
  operationLogsApi,
  housesApi,
  banksApi,
  enterpriseLoansApi,
  mycenterApi,
  commonFilesApi,
  upmsOrgsApi,
  bankUsersApi,
  channelApi,
  followRecordsApi,
};
