import request from "@/util/request";
import requestJson from "@/util/requestJson";
const urlPrefix = "v1/admin/enterprises";

export default {
  list(params) {
    return request.get(`${urlPrefix}`, params);
  },
  auditList(params) {
    return request.get(`${urlPrefix}/auditList`, params);
  },
  create(params) {
    return request.post(`${urlPrefix}`, params);
  },
  update(id, params) {
    return requestJson.put(`${urlPrefix}/${id}`, params);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
  delete(id) {
    return request.delete(`${urlPrefix}/delete/${id}`);
  },
  excelImport(params) {
    return request.upload(`${urlPrefix}/excel/import`, params);
  },
  updateLoanStatus(id, params) {
    return request.put(`${urlPrefix}/updateLoanStatus/${id}`, params);
  },
  audit(id, params) {
    return request.put(`${urlPrefix}/audit/${id}`, params);
  },
  // 上市公司财务简析
  financialAnalysis(params) {
    return request.get(`${urlPrefix}/getTyc798/${params.id}`, params);
  },
  // 上市公司信息
  stock(params) {
    return request.get(`${urlPrefix}/getTyc1075/${params.id}`, params);
  },
  // 高管信息
  seniorExecutive(params) {
    return request.get(`${urlPrefix}/getTyc855/${params.id}`, params);
  },
  // 十大股东
  shareholder(params) {
    return request.get(`${urlPrefix}/getTyc859/${params.id}`, params);
  },
  // 主要指标-年度
  mainIndex(params) {
    return request.get(`${urlPrefix}/getTyc967/${params.id}`, params);
  },
  // 主要指标-季度
  quarMainIndex(params) {
    return request.get(`${urlPrefix}/getTyc968/${params.id}`, params);
  },
  // 资产负债表
  balanceSheet(params) {
    return request.get(`${urlPrefix}/getTyc972/${params.id}`, params);
  },
  // 利润表
  profit(params) {
    return request.get(`${urlPrefix}/getTyc971/${params.id}`, params);
  },
  // 工商信息
  ic(params) {
    return request.get(`${urlPrefix}/getTyc1001/${params.id}`, params);
  },
  // 司法风险
  judicial(params) {
    return request.get(`${urlPrefix}/getTyc1002/${params.id}`, params);
  },
  top(id) {
    return request.put(`${urlPrefix}/top/${id}`);
  },
  cancelTop(id) {
    return request.put(`${urlPrefix}/cancelTop/${id}`);
  },
  // 股东信息
  shareholderInfo(params) {
    return request.get(`${urlPrefix}/getTyc821/${params.id}`, params);
  },
};
