import request from "@/util/request";

const urlPrefix = `v1/admin/upmsPermissions`;

export default {
  list(params) {
    return request.get(`${urlPrefix}`, {
      ...params,
    });
  },
  create(params) {
    return request.post(`${urlPrefix}`, {
      ...params,
    });
  },
  update(id, params) {
    return request.put(`${urlPrefix}/${id}`, {
      ...params,
    });
  },
  delete(id) {
    return request.delete(`${urlPrefix}/${id}`);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
};
