import request from "@/util/request";
const urlPrefix = "v1/admin/banks";

export default {
  list(params) {
    return request.get(`${urlPrefix}`, params);
  },
  create(params) {
    return request.post(`${urlPrefix}`, params);
  },
  update(id, params) {
    return request.put(`${urlPrefix}/${id}`, params);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
  delete(id) {
    return request.delete(`${urlPrefix}/${id}`);
  },
  top(id) {
    return request.put(`${urlPrefix}/top/${id}`);
  },
  cancelTop(id) {
    return request.put(`${urlPrefix}/cancelTop/${id}`);
  },
};
