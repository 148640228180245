import Enum from "./enum";

// api错误码
export const ResultCodeEnum = {
  success: new Enum("OK", "操作成功"),
  fail: new Enum("BUSINESS_FAIL", "操作失败"),
  inputError: new Enum("BAD_USER_INPUT", "参数错误"),
  error: new Enum("INTERNAL_SERVER_ERROR", "系统繁忙，请稍后再试"),
  unauthenticated: new Enum("UNAUTHENTICATED", "未登录"),
  forbidden: new Enum("FORBIDDEN", "未授权"),
  accountDisabled: new Enum("ACCOUNT_DISABLED", "账号已被禁用"),
  kickout: new Enum("KICKOUT", "被踢出登录"),
  enterprise_exists: new Enum("ENTERPRISE_EXISTS", "是否阀盖导入表格"),
};

// 列表数据默认初始化每页数量
export const PageSize = 10;
export const TableSize = "middle";
export const FormSize = "middle";

// 详情非必填字段没有内容的提示
export const no_content = "-";

// 企业登记状态
export const EnterpriseRegisterStatus = {
  survival: new Enum("survival", "存续"),
  on_business: new Enum("on_business", "在业"),
  revoked: new Enum("revoked", "吊销"),
  write_off: new Enum("write_off", "注销"),
  emigration: new Enum("emigration", "迁出"),
  immigration: new Enum("immigration", "迁入"),
  closed: new Enum("closed", "停业"),
  clearing: new Enum("clearing", "清算"),
};

// 企业类型
export const EnterpriseType = {
  state_owned: new Enum("state_owned", "国有企业"),
  collective_ownership: new Enum("collective_ownership", "集体企业"),
  people_owned: new Enum("people_owned", "民营企业"),
  privately_owned: new Enum("privately_owned", "私营企业"),
  macao_taiwan: new Enum("macao_taiwan", "港澳台企业"),
  foreign_owned: new Enum("foreign_owned", "外资企业"),
  joint_stock: new Enum("joint_stock", "股份制企业"),
  joint_stock_partnership: new Enum("joint_stock_partnership", "股份合作企业"),
  limited_partnership: new Enum("limited_partnership", "有限合伙企业"),
  joint_operation: new Enum("joint_operation", "联营企业"),
};

// 审核状态
export const AuditStatus = {
  to_audit: new Enum("to_audit", "待审核"),
  audit_refuse: new Enum("audit_refuse", "不通过"),
  audit_pass: new Enum("audit_pass", "通过"),
};

// 客户状态
export const TransactionStatus = {
  follow_up: new Enum("follow_up", "跟进中"),
  done_deal: new Enum("done_deal", "已交易"),
  give_up: new Enum("give_up", "储备客户"),
};

// 字典项(贷款状态ID)
export const loanStatusdictItemId = 1;
export const fileTypeItemId = 2;

// 预览前缀
export const TestPreviewUrl = "http://dev.benma-code.com/test/online-file-review2/onlinePreview?url=";
export const ProdPreviewUrl = "http://jinwangluo.com/online-file-review/onlinePreview?url=";
