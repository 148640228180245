import { message } from "antd";
message.config({
  top: 80,
  duration: 2,
});

export default {
  success: (msg, callback) => {
    message.success(msg, 1.5, callback ? callback : null);
  },
  error: (msg, callback) => {
    message.error(msg, 1.5, callback ? callback : null);
  },
  warning: (msg, callback) => {
    message.warning(msg, 1.5, callback ? callback : null);
  },
  loading: (msg, callback) => {
    message.loading(msg, 1.5, callback ? callback : null);
  },
};
