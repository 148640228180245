import request from "@/util/request";
const loanUrlPrefix = "v1/admin/enterpriseLoans";
const screenUrlPrefix = "v1/admin/screeningConditions";

export default {
  list(params) {
    return request.get(`${loanUrlPrefix}`, params);
  },
  create(params) {
    return request.post(`${loanUrlPrefix}`, params);
  },
  update(id, params) {
    return request.put(`${loanUrlPrefix}/${id}`, params);
  },
  detail(id) {
    return request.get(`${loanUrlPrefix}/${id}`);
  },
  delete(id) {
    return request.delete(`${loanUrlPrefix}/${id}`);
  },
  listForEnterprise(params) {
    return request.get(`${loanUrlPrefix}/listForEnterprise`, params);
  },
  progressList(params) {
    return request.get(`${loanUrlPrefix}/progressList`, params);
  },
  bankList(params) {
    return request.get(`${screenUrlPrefix}`, params);
  },
};
