import dialog from "@/util/dialog";
import { ResultCodeEnum, urls } from "@/api/index";

const defaultImageProps = {
  accept: "image/*",
  action: urls.uploadImage,
  beforeUpload: beforeUpload,
  className: "avatar-uploader",
  listType: "picture-card",
  name: "file",
  headers: { Client: "WEB" },
};

const defaultAttachmentProps = {
  // accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.tif,.txt",
  accept: ".",
  action: urls.uploadImage,
  beforeUpload: beforeUpload,
  name: "file",
  headers: { Client: "WEB" },
};

export function beforeUpload(file) {
  // const isLt1M = file.size / 1024 / 1024 < 100;
  // if (!isLt1M) {
  //   dialog.error("文件必须小于100M");
  // }
  // return isLt1M;
  return new Promise((resolve, reject) => {
    const isLt1M = file.size / 1024 / 1024 < 512;
    if (!isLt1M) {
      dialog.error(`上传附件超过500m了`);
      reject(file);
    } else {
      resolve(file);
    }
  });
}

/**
 * @param successCb 上传成功后回调
 * @param errorCb 上传失败后回调
 * @param options 上传信息(对应的字段)
 */
export const radio_image = function(successCb, uploadingCb, fileList) {
  return {
    ...defaultImageProps,
    ...{
      // fileList: fileList,
      showUploadList: false,
      onChange: (info) => {
        console.log(info);
        const file = info.file;
        const status = file.status;
        if (status === "uploading") {
          uploadingCb && uploadingCb("uploading");
        }
        if (status === "done") {
          console.log(file.response);
          if (file.response && typeof file.response === "object") {
            if (file.response.code === ResultCodeEnum.success.code) {
              successCb && successCb(file.response.data);
            } else {
              dialog.error(file.response.errorMsg);
            }
          }
        }
      },
    },
  };
};

/**
 * @param successCb 上传成功后回调
 * @param errorCb 上传失败后回调
 * @param options 上传信息(对应的字段)
 */
export const radio_attachment = function(successCb, uploadingCb, fileList) {
  return {
    ...defaultAttachmentProps,
    ...{
      // fileList: fileList,
      showUploadList: false,
      onChange: (info) => {
        console.log(info);
        const file = info.file;
        const status = file.status;
        if (status === "uploading") {
          uploadingCb && uploadingCb("uploading");
        }
        if (status === "done") {
          console.log(file.response);
          if (file.response && typeof file.response === "object") {
            if (file.response.code === ResultCodeEnum.success.code) {
              successCb &&
                successCb({
                  url: file.response.data,
                  name: file.name,
                });
            } else {
              dialog.error(file.response.errorMsg);
            }
          }
        }
      },
    },
  };
};
