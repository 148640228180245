import dialog from "@/util/dialog";
import { ResultCodeEnum, urls } from "@/api/index";

const defaultProps = {
  accept: "image/*",
  action: urls.uploadImage,
  beforeUpload: beforeUpload,
  className: "avatar-uploader",
  listType: "picture-card",
  name: "file",
  headers: { Client: "web" },
};

function beforeUpload(file) {
  const isLt1M = file.size / 1024 / 1024 < 10;
  if (!isLt1M) {
    dialog.error("文件必须小于10M");
  }
  return isLt1M;
}

/**
 * @param successCb 上传成功后回调
 * @param errorCb 上传失败后回调
 * @param options 上传信息(对应的字段)
 */
export const radio_image = function(successCb, uploadingCb, fileList) {
  const props = {
    ...defaultProps,
    ...{
      // fileList: fileList,
      showUploadList: false,
      onChange: (info) => {
        console.log(info);
        const file = info.file;
        const status = file.status;
        if (status === "uploading") {
          uploadingCb && uploadingCb("uploading");
        }
        if (status === "done") {
          console.log(file.response);
          if (file.response) {
            if (typeof file.response === "object") {
              if (file.response.code === ResultCodeEnum.success.code) {
                successCb && successCb(file.response.data);
              } else {
                dialog.error(file.response.errorMsg);
              }
            }
          }
        }
      },
    },
  };
  return props;
};

/**
 * @param successCb 上传成功后回调
 * @param errorCb 上传失败后回调
 * @param options 上传信息(对应的字段)
 */
export const radio_attachment = function(successCb, uploadingCb, fileList) {
  const props = {
    ...defaultProps,
    ...{
      // fileList: fileList,
      showUploadList: false,
      onChange: (info) => {
        console.log(info);
        const file = info.file;
        const status = file.status;
        if (status === "uploading") {
          uploadingCb && uploadingCb("uploading");
        }
        if (status === "done") {
          console.log(file.response);
          if (file.response) {
            if (typeof file.response === "object") {
              if (file.response.code === ResultCodeEnum.success.code) {
                successCb && successCb(file.response.data);
              } else {
                dialog.error(file.response.errorMsg);
              }
            }
          }
        }
      },
    },
  };
  return props;
};
