const moneyReg = /^(([1-9]\d*)|\d)(\.\d{1,2})?$/; // 金额校验正则,保留两位数
const ratioReg = /^(([1-9]\d*)|\d)(\.\d{1,3})?$/; // 比例校验正则,保留两位数
const positiveIntegerReg = /^([1-9][0-9]*)$/; // 正整数校验正则,大于等于0

const positiveNumberReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/; // 正数,支持小数点

/** 获取文本字符长度 */
const getStrLength = (str) => {
  str = str.toString();
  // return str.replace(/[\u0391-\uFFE5]/g, "aa").length;
  return str.length;
};
/**
 * 校验金额,最多保留两位小数点
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 */
export function checkMoney(rule, value, callback) {
  if (value) {
    // 判断是否等于0的数值
    if (value === 0) {
      return callback();
    } else {
      // 判断是否大于0
      if (value > 0) {
        if (moneyReg.test(value)) {
          return callback();
        } else {
          return callback("请保留两位小数");
        }
      } else {
        return callback("请输入正整数");
      }
    }
  } else {
    return callback();
  }
}

/**
 * 校验正整数,大于等于0
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 */
export function checkNumber(rule, value, callback) {
  if (value) {
    if (value === 0) {
      return callback();
    } else {
      if (positiveIntegerReg.test(value)) {
        return callback();
      } else {
        return callback("请输入正整数");
      }
    }
  } else {
    return callback();
  }
}

/**
 * 校验文本长度
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 * @param {number} length 字符长度
 */
export function checkStringlength(rule, value, callback, length) {
  if (value) {
    const textLength = getStrLength(value);
    if (textLength > length) {
      return callback(`不能超过${length}个字符`);
    } else {
      return callback();
    }
  } else {
    return callback();
  }
}

/**
 * 校验金额,最多保留两位小数点
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 */
export function checkRatio(rule, value, callback) {
  if (value) {
    // 判断是否等于0的数值
    if (value === 0) {
      return callback();
    } else {
      // 判断是否大于0
      if (value > 0) {
        if (ratioReg.test(value)) {
          return callback();
        } else {
          return callback("最多保留三位小数");
        }
      } else {
        return callback("请输入正整数");
      }
    }
  } else {
    return callback();
  }
}

/**
 * 校验正整数,大于等于0
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 */
export function checkPositiveNumber(rule, value, callback) {
  if (value) {
    if (value === 0) {
      return callback();
    } else {
      if (positiveNumberReg.test(value)) {
        return callback();
      } else {
        return callback("请输入正数");
      }
    }
  } else {
    return callback();
  }
}

/**
 * 校验数字
 * @param {obj} rule 表单信息(包括字段名/类型等)
 * @param {number} value 表单输入框的值
 */
export function checkDigit(rule, value, callback) {
  if (value) {
    if (value === 0) {
      return callback();
    } else {
      const regPos = /^\d+(\.\d+)?$/; // 非负浮点数
      const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
      if (regPos.test(value) || regNeg.test(value)) {
        return callback();
      } else {
        return callback("请输入数字");
      }
    }
  } else {
    return callback();
  }
}
