import React from "react";

class LogoComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: "inline-block", width: 36, height: 64 }}>
        <svg
          height="224.02"
          style={{ display: "block", width: "100%", height: "100%" }}
          viewBox="0 0 213.28 224.02"
          width="213.28"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g color="#1578ff" fill="#1578ff" stroke="currentColor" strokeWidth="4">
            <path d="M88.9,6.49,74.66,20.59l2.9,4.12,4.93.39a2.18,2.18,0,1,0,2.76.22l8.41.66a2.19,2.19,0,1,0,3.21,1.94A2.16,2.16,0,0,0,96,26.17l6.35.5s4.84,6.41,12.51.23c1.38-.18,3.63-.4,6.23-.64a2.19,2.19,0,1,0,2.55-.23c2.88-.25,6-.5,8.6-.71a2.19,2.19,0,1,0,2.16-.17l4.78-.36,3.2-4.21c-4.85-5.11-9.77-9.84-14.62-14.95-.86-.73-2,0-1.17,1.17l9.54,13.84-20.8,1a17.11,17.11,0,0,0-1.42-2.55l10.8-.81v-.63H112.88a5.57,5.57,0,0,0-2.46-2.06L108.83,0h-.77l-1,15.63a6.74,6.74,0,0,0-3.27,2H92v.63l10.67.84a13.39,13.39,0,0,0-1.09,2.21c-4.75,0-21-.78-21-.78,3-4.36,8.48-12.53,9.14-13.08A.66.66,0,0,0,88.9,6.49Z" />
            <path d="M180.73,54.41a106.11,106.11,0,0,0,0,125.93q-2.55,3-5.34,5.78a97.55,97.55,0,0,1-8.63,7.67,125.37,125.37,0,0,1,0-152.83,97.55,97.55,0,0,1,8.63,7.67C177.25,50.49,179,52.43,180.73,54.41Z" />
            <path d="M30.38,177.69a105.65,105.65,0,0,0,18.8-60.31,105.63,105.63,0,0,0-18.8-60.31,96.38,96.38,0,0,1,7.51-8.44q3-2.94,6.1-5.61a124.77,124.77,0,0,1,24.44,74.36A124.74,124.74,0,0,1,44,191.73q-3.15-2.65-6.1-5.61A97.73,97.73,0,0,1,30.38,177.69Z" />
            <path d="M106.64,211.66a94.31,94.31,0,0,0,33.8-182.32l7-8.57-2.33-2.46-.63-.66a106.52,106.52,0,1,1-70.6-1.78L69.67,20l5.74,8.4a94.29,94.29,0,0,0,31.23,183.26Z" />
            <rect height="16.49" width="68.53" x="80.37" y="114.54" />
            <rect height="16.49" width="76.86" x="51.84" y="90.04" />
          </g>
        </svg>
      </div>
    );
  }
}

export default LogoComponent;
