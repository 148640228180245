import request from "@/util/request";

const urlPrefix = `v1/base/mdDistricts`;
export default {
  getListProvinces() {
    return request.get(`${urlPrefix}/listProvinces`);
  },
  getListChildren(params) {
    return request.get(`${urlPrefix}/listChildren`, { ...params });
  },
  getListSiblings(params) {
    return request.get(`${urlPrefix}/listSiblings`, { ...params });
  },
  getParentPaths(params) {
    return request.get(`${urlPrefix}/getParentPaths`, { ...params });
  },
};
