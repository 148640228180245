import request from "@/util/request";
const urlPrefix = "v1/base";

export default {
  listProvinces() {
    return request.get(`${urlPrefix}/mdDistricts/listProvinces`);
  },
  listChildren(params) {
    return request.get(`${urlPrefix}/mdDistricts/listChildren`, params);
  },
  getParentPaths(params) {
    return request.get(`${urlPrefix}/mdDistricts/getParentPaths`, params);
  },
  smscode(params) {
    return request.post(`${urlPrefix}/smscode/send`, params);
  },
  enterprises(params) {
    return request.post2(`${urlPrefix}/enterprises`, params);
  },
  verifyRecommendCode(recommendationCode) {
    return request.post2(`${urlPrefix}/recommendCode/verify?recommendationCode=${recommendationCode}`, {});
  },
};
