import request from "@/util/request";
const urlPrefix = "v1/admin/channels";

export default {
  list(params) {
    return request.get2(`${urlPrefix}`, params);
  },
  recordList(params) {
    return request.get2(`${urlPrefix}/recordList`, params);
  },
  create(params) {
    return request.post2(`${urlPrefix}`, params);
  },
  update(params) {
    return request.put2(`${urlPrefix}`, params);
  },
  detail(id) {
    return request.get2(`${urlPrefix}/${id}`);
  },
  delete(id) {
    return request.delete2(`${urlPrefix}/${id}`);
  },
  isEnable(params) {
    return request.put2(`${urlPrefix}/isEnable`, params);
  },
};
