import request from "@/util/request";
const urlPrefix = "v1/admin/mdConfigures";

export default {
  updateByCode(params) {
    return request.put(`${urlPrefix}/updateByCode`, params);
  },
  detail(params) {
    return request.get(`${urlPrefix}/getByCode`, params);
  },
};
