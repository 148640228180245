import request from "@/util/request";
import requestJson from "@/util/requestJson";
const urlPrefix = "v1/admin/commonFiles";

export default {
  list(params) {
    return request.get(`${urlPrefix}`, params);
  },
  create(params) {
    return requestJson.post(`${urlPrefix}`, params);
  },
  update(params) {
    return requestJson.put(`${urlPrefix}`, params);
  },
  detail(id) {
    return request.get(`${urlPrefix}/${id}`);
  },
  delete(id) {
    return request.delete(`${urlPrefix}/${id}`);
  },
  download(id) {
    return request.get(`${urlPrefix}/download/${id}`);
  },
};
