import md5 from "md5";

export default {
  md5(str) {
    return md5(str);
  },

  /**
   * 日期格式化工具
   * @param date 日期,格式是时间戳
   * @param formatStr 转换后返回格式 如: YYYY-MM-DD hh:ii:ss
   */
  formatDate: function(date, formatStr) {
    if (!date) {
      return "";
    }
    date = new Date(date);
    function $addZero(v, size) {
      for (let i = 0, len = size - (v + "").length; i < len; i++) {
        v = "0" + v;
      }
      return v + "";
    }

    // 格式化时间
    const arrWeek = ["日", "一", "二", "三", "四", "五", "六"];
    return formatStr
      .replace(/yyyy|YYYY/, date.getFullYear())
      .replace(/yy|YY/, $addZero(date.getFullYear() % 100, 2))
      .replace(/mm|MM/, $addZero(date.getMonth() + 1, 2))
      .replace(/m|M/g, date.getMonth() + 1)
      .replace(/dd|DD/, $addZero(date.getDate(), 2))
      .replace(/d|D/g, date.getDate())
      .replace(/hh|HH/, $addZero(date.getHours(), 2))
      .replace(/h|H/g, date.getHours())
      .replace(/ii|II/, $addZero(date.getMinutes(), 2))
      .replace(/i|I/g, date.getMinutes())
      .replace(/ss|SS/, $addZero(date.getSeconds(), 2))
      .replace(/s|S/g, date.getSeconds())
      .replace(/w/g, date.getDay())
      .replace(/W/g, arrWeek[date.getDay()]);
  },

  /**
   * 金额分转换为元,保留两位小数点
   * @param dollar 金额(单位:分)
   */
  moneyMinuteToYuan: function(dollar) {
    if (!isNaN(dollar)) {
      dollar = (dollar * 0.01).toFixed(2); // 分到元
      return dollar;
    }
  },

  // html转义
  HTMLEncode: function(html) {
    let temp = document.createElement("div");
    temp.textContent !== null ? (temp.textContent = html) : (temp.innerText = html);
    const output = temp.innerHTML;
    temp = null;
    return output;
  },

  // html反转义
  HTMLDecode: function(text) {
    let temp = document.createElement("div");
    temp.innerHTML = text;
    const output = temp.innerText || temp.textContent;
    temp = null;
    return output;
  },

  /**
   * 获取树形数据最后一层children的id集合
   * @param data 树形数据
   * @param ids id集合
   */
  getTreeDataLastId: function(data, ids) {
    const _this = this;
    data.map((el) => {
      if (el.children !== undefined) {
        return _this.getTreeDataLastId(el.children, ids);
      } else {
        ids.push(el.value);
      }
    });
    return ids;
  },

  /**
   * 将数据转化为树形结构
   * @param flatArrs 数据
   * @param parentItem 父层的id
   */
  transformTreeData: function(flatArrs, parentItem) {
    const children = flatArrs
      .filter((item, index) => {
        return item.parentId === parentItem.id;
      })
      .map((record) => {
        record.label = record.name;
        record.value = record.code;
        record.key = record.code;
        return record;
      });
    if (children.length !== 0) {
      parentItem.children = children;
    }
    for (const i in children) {
      this.transformTreeData(flatArrs, children[i]);
    }
    return parentItem.children;
  },

  /**
   * 自定义字段返回树形结构数据
   * @param data 数据
   */
  getTreeData: function(data) {
    const _this = this;
    return data.length
      ? data.map((o) => {
          const oNew = {
            title: o.name,
            key: o.id,
            value: o.id,
            type: o.type,
            children: _this.getTreeData(o.children),
          };
          if (oNew.children === undefined) delete oNew.children;
          return oNew;
        })
      : undefined;
  },

  /** 过滤对象属性值为空 */
  filterObjProperty: function(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  },
};
