import request from "@/util/request";

const urlPrefix = `v1/login/admin/web`;
export default {
  onLogin(params) {
    return request.post(`${urlPrefix}/login`, {
      ...params,
    });
  },
  onLogout() {
    return request.post(`${urlPrefix}/logout`);
  },
  verifyLogin() {
    return request.get(`${urlPrefix}/verify_login`);
  },
};
